import * as React from 'react';

import { cn } from '@/lib/cn';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/16/solid';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);
    return (
      <div className='relative flex justify-between'>
        <input
          type={showPassword ? 'text' : 'password'}
          className={cn(
            'flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm leading-none shadow-input ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/70 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          ref={ref}
          {...props}
        />
        <button
          className='absolute right-3 top-1/2 my-auto -translate-y-1/2 focus:outline-none focus-visible:ring-2 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50'
          type='button'
          onClick={() => setShowPassword((prev) => !prev)}
        >
          <span className='sr-only'>
            {showPassword ? 'Dölj lösenord' : 'Visa lösenord'}
          </span>
          {showPassword ? (
            <EyeSlashIcon className='size-4' />
          ) : (
            <EyeIcon className='size-4' />
          )}
        </button>
      </div>
    );
  }
);
PasswordInput.displayName = 'PasswordInput';

export type FormInputProps<TFieldValues extends FieldValues = FieldValues> =
  InputProps & {
    label: string;
    name: string;
    placeholder?: string;
    form: UseFormReturn<TFieldValues>;
    description?: string;
  };

export { PasswordInput };
