import type { ResponseCredentials } from '@/app/(auth)/types';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

// VERIFICATION_METHOD_HOTP_EMAIL = 0
// VERIFICATION_METHOD_HOTP_SMS = 1
// VERIFICATION_METHOD_TOTP = 2
// VERIFICATION_METHOD_BANK_ID = 3

export type MFA_TYPE = 0 | 1 | 2 | 3;

type LoginCredentials = {
  username: string;
  password: string;
  new_password?: string;
  mfa_code?: string;
  mfa_type?: MFA_TYPE;
  customerMode?: boolean;
  customerId?: string;
};

export type LoginCredentialsError = {
  error: string;
  status?: number;
};

export function useAuthCredentials(
  options?: Omit<
    UseMutationOptions<
      ResponseCredentials,
      LoginCredentialsError,
      LoginCredentials
    >,
    'mutationFn'
  >
) {
  return useMutation({
    ...options,
    mutationFn: (props) => login(props),
  });
}

async function login(credentials: Omit<LoginCredentials, 'new_password'>) {
  const { customerId }: LoginCredentials = credentials;
  const { cookies } = await getCookies();

  if (!credentials.username || !credentials.password) {
    throw {
      error: 'Ange användarnamn och lösenord',
      status: 400,
    };
  }

  const res = await fetch(
    `${BASE_BACKEND_URL}/accounts/users/auth/login/credentials/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-PIGELLO-CUSTOMER-ID': customerId ?? cookies.customer_id,
      },
      body: JSON.stringify({
        ...credentials,
        user_type: credentials.customerMode
          ? 'accounts.tenant'
          : 'accounts.organizationuser',
      }),
    }
  );

  return res.json();
}
