import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';
import { Label } from '@/components/ui/label';
import OverlayLoader from '@/components/ui/overlay-loader';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { toast } from 'sonner';
import { useAuthHotpLink } from '../../hotp/hooks/useHotpDistribute';
import type { MFA_TYPE } from '../hooks/useAuthCredentials';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';

export type FormValues = {
  mfaCode: string;
};

interface HOTPLoginProps {
  login: (mfaCode: string, mfaType: MFA_TYPE) => void;
  isSubmitting: boolean;
  hasMfaHOTP: boolean;
  setHasMfaHOTP: Dispatch<SetStateAction<boolean>>;
  username: string;
  password: string;
  isHOTPIPAddressVerification: boolean;
  customerMode: boolean;
  hotpOptions: number;
}

export function SignInWithHOTP({
  login,
  isSubmitting,
  hasMfaHOTP,
  setHasMfaHOTP,
  username,
  password,
  isHOTPIPAddressVerification,
  customerMode,
  hotpOptions,
}: HOTPLoginProps) {
  const [hasSentCode, setHasSentCode] = useState(false);
  const [kind, setKind] = useState('email');
  const hasSelectedEmail = kind === 'email';
  const mfaType = hasSelectedEmail ? 0 : 1;

  const { mutateAsync: sendHotpCode, isPending } = useAuthHotpLink({});

  const form = useForm<FormValues>({
    defaultValues: {
      mfaCode: '',
    },
    disabled: isSubmitting,
  });

  const onSubmitRequest = () => {
    toast.promise(sendHotpCode({ username, password, customerMode, mfaType }), {
      loading: 'Skickar inloggningskod',
      success: () => {
        setHasSentCode(true);
        return 'Inloggningskod skickad';
      },
      error: 'Kunde inte skicka inloggningskod',
    });
  };
  const onSubmit = (data: FormValues) => {
    login(data.mfaCode, mfaType);
  };

  const value = useWatch({
    name: 'mfaCode',
    control: form.control,
  });

  const clearAndClose = () => {
    form.reset();
    setHasSentCode(false);
    setHasMfaHOTP(false);
  };

  return (
    <AlertDialog open={hasMfaHOTP} onOpenChange={clearAndClose}>
      <AlertDialogContent className='relative w-[calc(100%-2rem)] max-w-md'>
        {isSubmitting && <OverlayLoader />}
        <AlertDialogHeader>
          <AlertDialogTitle>2-faktorsautentisering</AlertDialogTitle>
          <AlertDialogDescription>
            {isHOTPIPAddressVerification &&
              'IP-adressen du loggar in ifrån måste verifieras för att fortsätta. '}
            {!hasSentCode && hotpOptions === 2 && (
              <Tabs value={kind} onValueChange={setKind}>
                <TabsList className='mb-3 mt-2 flex items-center justify-between'>
                  <TabsTrigger
                    className='w-full'
                    value='email'
                    disabled={hasSentCode}
                  >
                    E-post
                  </TabsTrigger>
                  <TabsTrigger
                    className='w-full'
                    value='sms'
                    disabled={hasSentCode}
                  >
                    SMS
                  </TabsTrigger>
                </TabsList>
              </Tabs>
            )}
            {hasSentCode
              ? `Skriv in den 6-siffriga kod du fick per ${hasSelectedEmail ? 'mail' : 'SMS'}. Om du har fått en länk så är det de sista 6 siffrorna i länken som ska användas.`
              : hotpOptions === 2
                ? 'Var god välj om din inloggningskod ska skickas via e-post eller SMS.'
                : `För att logga in skickas en inloggningskod till din ${hotpOptions === 0 ? 'E-postadress' : 'telefon'}.`}
          </AlertDialogDescription>
        </AlertDialogHeader>
        {hasSentCode ? (
          <Form {...form}>
            <form
              onSubmit={(e) => {
                form.clearErrors();
                form.handleSubmit(onSubmit)(e);
              }}
            >
              <div className='grid gap-2'>
                <Label htmlFor='mfaCode' className='text-foreground'>
                  Kod för 2-faktorsautentisering
                </Label>
                <InputOTP
                  id='mfaCode'
                  value={value}
                  maxLength={6}
                  onChange={(value) => form.setValue('mfaCode', value)}
                  disabled={isSubmitting}
                  onKeyUp={(e) => {
                    if (
                      value.length === 6 &&
                      form.formState.submitCount === 0
                    ) {
                      form.handleSubmit(onSubmit)(e);
                    }
                  }}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                >
                  <InputOTPGroup>
                    <InputOTPSlot className='size-14 text-lg' index={0} />
                    <InputOTPSlot className='size-14 text-lg' index={1} />
                    <InputOTPSlot className='size-14 text-lg' index={2} />
                    <InputOTPSlot className='size-14 text-lg' index={3} />
                    <InputOTPSlot className='size-14 text-lg' index={4} />
                    <InputOTPSlot className='size-14 text-lg' index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </div>
              <div className='mt-4 grid w-full gap-2'>
                <Button
                  disabled={value.length !== 6 || isSubmitting}
                  type='submit'
                >
                  Logga in
                </Button>
                <Button type='button' onClick={clearAndClose} variant='outline'>
                  Avbryt
                </Button>
              </div>
            </form>
          </Form>
        ) : (
          <Button
            className='self-end'
            onClick={onSubmitRequest}
            disabled={isPending}
          >
            Skicka inloggningskod
          </Button>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
