'use client';

import { Icons } from '@/components/icons';
import {
  WorkspaceSelector,
  type RoleSegmentCombination,
} from '@/components/sidebar/components/workspace-selector';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button, buttonVariants } from '@/components/ui/button';
import { Form, FormLabel } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import OverlayLoader from '@/components/ui/overlay-loader';
import { PasswordInput } from '@/components/ui/password-input';
import { useGetCookies } from '@/hooks/use-get-cookies';
import { cn } from '@/lib/cn';
import { Urls } from '@/lib/urls';
import { DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/16/solid';
import Link from 'next/link';
import { useRouter, useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'sonner';
import { useGetBranding } from '../../hooks/use-get-branding';
import type { ResponseCredentials } from '../../types';
import {
  handleHotpCode,
  handleRoleSegmentsCombination,
  setAuthCookies,
  setCustomerId,
} from '../utils';
import { ActivateMFA } from './activate-mfa';
import {
  useAuthCredentials,
  type LoginCredentialsError,
  type MFA_TYPE,
} from './hooks/useAuthCredentials';
import { SignInWithHOTP } from './hotp';
import { SignInWithTOTP } from './totp';

interface FormValues {
  username: string;
  password: string;
  customerId: string;
}

export default function UserAuthForm() {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(true);
  const { data: brandingData } = useGetBranding();
  const params = useSearchParams();
  const customerMode = params.get('customer') === 'true';

  const [selectTOTPorHOTPOpen, setSelectTOTPorHOTPOpen] = useState(false);
  const [hasMfaTOTP, setHasMfaTOTP] = useState(false);
  const [hasMfaHOTP, setHasMfaHOTP] = useState(false);
  const [hotpOptions, setHotpOptions] = useState(0);

  const [isMFAActivation, setIsMFAActivation] = useState(false);
  const [isHOTPIPAddressVerification, setHOTPIsIPAddressVerification] =
    useState(false);
  const [roleSegmentCombination, setRoleSegmentCombination] =
    useState<RoleSegmentCombination[]>();
  const { cookies } = useGetCookies();

  const form = useForm<FormValues>({
    defaultValues: {
      username: '',
      password: '',
      customerId: '',
    },
    values: {
      username: '',
      password: '',
      customerId: brandingData?.customer_id ?? cookies?.customer_id ?? '',
    },
  });

  const success = async (res: ResponseCredentials) => {
    if (res && 'code' in res) {
      const {
        redirectUrl,
        isError,
        message,
        mfaTOTP,
        mfaHOTP,
        isIPAddressVerification,
        isMFAActivation,
        mfaTOTPorHOTP,
      } = handleHotpCode({
        code: res.code,
        customerMode: customerMode,
      });

      if (isMFAActivation) {
        setIsMFAActivation(true);
        return;
      }

      if (mfaTOTPorHOTP) {
        setSelectTOTPorHOTPOpen(true);
        setHotpOptions(res?.hotp_options ?? 0);
        return;
      }

      if (mfaTOTP) {
        setHasMfaTOTP(true);
        return;
      }

      if (mfaHOTP) {
        setHasMfaHOTP(true);
        setHotpOptions(res?.hotp_options ?? 0);
        if (isIPAddressVerification) {
          setHOTPIsIPAddressVerification(true);
        }
        return;
      }

      if (isError) {
        return toast.error(message);
      }
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    }

    if (res && 'token' in res) {
      if (hasMfaTOTP) {
        setHasMfaTOTP(false);
      }
      setAuthCookies(res);

      // tenant user
      if (customerMode) {
        return router.push('/tenantusertest');
      }

      const data = await handleRoleSegmentsCombination();

      if (data && data.length > 0) {
        setRoleSegmentCombination(data);
        setIsOpen(true);
      }
      if (!data || data?.length === 0) {
        router.push(Urls.dashboard.home);
        return;
      }
    }
  };

  const error = (err: LoginCredentialsError) => {
    toast.error(err.error, {
      description: err.status,
    });
  };

  const { mutate, isPending } = useAuthCredentials({
    onError: error,
    onSuccess: success,
  });

  const onSubmit = (data: FormValues) => {
    setCustomerId(data.customerId);
    mutate({ ...data, customerMode: customerMode });
  };

  const onSubmitMfaCode = (mfaCode: string, mfaType: MFA_TYPE) => {
    const data = form.getValues();
    setCustomerId(data.customerId);
    mutate({
      ...data,
      mfa_code: mfaCode,
      mfa_type: mfaType,
      customerId: data.customerId,
      customerMode: customerMode,
    });
  };

  const { errors } = form.formState;

  return (
    <>
      <div className='flex flex-col gap-2 text-center'>
        <h1 className='text-2xl font-semibold tracking-tight'>
          Logga in{' '}
          {brandingData?.branding?.[0]?.name
            ? `till ${brandingData?.branding[0].name}`
            : ''}
        </h1>
        <p className='text-sm text-muted-foreground'>
          Skriv in dina uppgifter nedan
        </p>
      </div>
      <div className={'grid gap-6'}>
        <div className='grid gap-2'>
          <div className='grid gap-1'>
            <Form {...form}>
              <form
                onSubmit={form.handleSubmit(onSubmit)}
                className='grid gap-8'
              >
                {!brandingData?.customer_id && (
                  <div className='grid gap-2'>
                    <FormLabel htmlFor='customerId'>Kund-id</FormLabel>
                    <Input
                      {...form.register('customerId', {
                        required: 'Kund-id är obligatoriskt',
                      })}
                      id='customerId'
                      placeholder='Kund-id'
                    />
                    {errors.customerId && (
                      <p className='font-medium text-destructive'>
                        {errors.customerId.message}
                      </p>
                    )}
                  </div>
                )}
                <div className='grid gap-2'>
                  <FormLabel htmlFor='username'>Användarnamn</FormLabel>
                  <Input
                    {...form.register('username', {
                      required: 'Vänligen fyll i användarnamn',
                    })}
                    id='username'
                    placeholder='Användarnamn'
                    autoComplete='username'
                  />
                  {errors.username && (
                    <p className='font-medium text-destructive'>
                      {errors.username.message}
                    </p>
                  )}
                </div>
                <div className='grid gap-2'>
                  <FormLabel htmlFor='password'>Lösenord</FormLabel>
                  <PasswordInput
                    {...form.register('password', {
                      required: 'Vänligen fyll i lösenord',
                    })}
                    id='password'
                    placeholder='Lösenord'
                    autoComplete='current-password'
                  />
                  {errors.password && (
                    <p className='font-medium text-destructive'>
                      {errors.password.message}
                    </p>
                  )}
                  <Link
                    prefetch={false}
                    className={cn(
                      buttonVariants({
                        variant: 'link',
                      }),
                      'justify-start px-0'
                    )}
                    href='/login/credentials/forgot-password'
                  >
                    Glömt lösenord?
                  </Link>
                </div>
                <Button className='w-full' type='submit'>
                  {isPending && (
                    <Icons.loader className='mr-2 size-4 animate-spin' />
                  )}
                  Logga in
                </Button>
              </form>
            </Form>
          </div>
        </div>
        <div className='relative'>
          <div className='absolute inset-0 flex items-center'>
            <span className='w-full border-t' />
          </div>
          <div className='relative flex justify-center text-xs uppercase'>
            <span className='bg-background px-2 text-muted-foreground'>
              Eller fortsätt med
            </span>
          </div>
        </div>
        <div className='flex w-full items-center gap-2'>
          {brandingData?.branding?.[0] && (
            <Link
              prefetch={false}
              href={'/login/bank-id'}
              className={cn('w-full', buttonVariants({ variant: 'outline' }))}
            >
              BankID
            </Link>
          )}
          <Link
            prefetch={false}
            href={'/login/magic-link'}
            className={cn(' w-full ', buttonVariants({ variant: 'outline' }))}
          >
            Inloggningslänk
          </Link>
        </div>
        {roleSegmentCombination && (
          <WorkspaceSelector
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            roleSegmentCombination={roleSegmentCombination}
          />
        )}
      </div>

      {selectTOTPorHOTPOpen && (
        <AlertDialog defaultOpen>
          <AlertDialogContent className='max-w-[700px]'>
            <AlertDialogHeader>
              <AlertDialogTitle>2-faktorsautentisering krävs</AlertDialogTitle>
            </AlertDialogHeader>

            <AlertDialogDescription>
              Välj om du vill använda verifiering via app så som{' '}
              <strong>Microsoft Authenticator</strong> eller{' '}
              <strong>Google Authenticator</strong>) eller verifiering via en
              kod som skickas antingen till din <strong>E-post</strong> eller{' '}
              <strong>telefon</strong>
            </AlertDialogDescription>

            <AlertDialogFooter>
              <Button
                className='w-1/2'
                variant='secondary'
                onClick={() => {
                  setSelectTOTPorHOTPOpen(false);
                  setHasMfaTOTP(true);
                }}
              >
                Verifiering via app{' '}
                <DevicePhoneMobileIcon className='ml-1 size-4' />
              </Button>
              <Button
                className='w-1/2'
                variant='secondary'
                onClick={() => {
                  setSelectTOTPorHOTPOpen(false);
                  setHasMfaHOTP(true);
                }}
              >
                Verifiering via kod per E-post eller SMS{' '}
                <EnvelopeIcon className='ml-1 size-4' />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}

      <SignInWithTOTP
        hasMfaTOTP={hasMfaTOTP}
        setHasMfaTOTP={setHasMfaTOTP}
        isSubmitting={isPending}
        login={(mfaCode, mfaType) => onSubmitMfaCode(mfaCode, mfaType)}
      />
      <SignInWithHOTP
        hasMfaHOTP={hasMfaHOTP}
        hotpOptions={hotpOptions}
        password={form.getValues('password')}
        username={form.getValues('username')}
        setHasMfaHOTP={setHasMfaHOTP}
        isSubmitting={isPending}
        isHOTPIPAddressVerification={isHOTPIPAddressVerification}
        login={(mfaCode, mfaType) => onSubmitMfaCode(mfaCode, mfaType)}
        customerMode={customerMode}
      />
      <ActivateMFA
        isActivateMFA={isMFAActivation}
        password={form.getValues('password')}
        username={form.getValues('username')}
        setIsActivateMFA={setIsMFAActivation}
        isSubmitting={isPending}
        customerMode={customerMode}
      />
      {isPending && !hasMfaTOTP && !hasMfaHOTP && <OverlayLoader />}
    </>
  );
}
