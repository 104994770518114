import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';
import { Label } from '@/components/ui/label';
import OverlayLoader from '@/components/ui/overlay-loader';
import { type Dispatch, type SetStateAction } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import type { MFA_TYPE } from '../hooks/useAuthCredentials';

export type FormValues = {
  mfaCode: string;
};

interface TOTPLoginProps {
  login: (mfaCode: string, mfaType: MFA_TYPE) => void;
  isSubmitting: boolean;
  hasMfaTOTP: boolean;
  setHasMfaTOTP: Dispatch<SetStateAction<boolean>>;
}

export function SignInWithTOTP({
  login,
  isSubmitting,
  hasMfaTOTP,
  setHasMfaTOTP,
}: TOTPLoginProps) {
  const form = useForm<FormValues>({
    defaultValues: {
      mfaCode: '',
    },
    disabled: isSubmitting,
  });

  const onSubmit = (data: FormValues) => {
    login(data.mfaCode, 2);
  };

  const value = useWatch({
    name: 'mfaCode',
    control: form.control,
  });

  const clearAndClose = () => {
    form.reset();
    setHasMfaTOTP(false);
  };

  return (
    <AlertDialog open={hasMfaTOTP} onOpenChange={clearAndClose}>
      <AlertDialogContent className='relative w-[calc(100%-2rem)] max-w-md'>
        {isSubmitting && <OverlayLoader />}
        <AlertDialogHeader>
          <AlertDialogTitle>2-faktorsautentisering</AlertDialogTitle>
          <AlertDialogDescription>
            Skriv in din kod från din 2-faktorsautentiseringsapp.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <Form {...form}>
          <form
            onSubmit={(e) => {
              form.clearErrors();
              form.handleSubmit(onSubmit)(e);
            }}
          >
            <div className='grid gap-2'>
              <Label htmlFor='mfaCode' className='text-foreground'>
                Kod för 2-faktorsautentisering
              </Label>
              <InputOTP
                id='mfaCode'
                value={value}
                maxLength={6}
                onChange={(value) => form.setValue('mfaCode', value)}
                disabled={isSubmitting}
                onKeyUp={(e) => {
                  if (value.length === 6 && form.formState.submitCount === 0) {
                    form.handleSubmit(onSubmit)(e);
                  }
                }}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
              >
                <InputOTPGroup>
                  <InputOTPSlot className='size-14 text-lg' index={0} />
                  <InputOTPSlot className='size-14 text-lg' index={1} />
                  <InputOTPSlot className='size-14 text-lg' index={2} />
                  <InputOTPSlot className='size-14 text-lg' index={3} />
                  <InputOTPSlot className='size-14 text-lg' index={4} />
                  <InputOTPSlot className='size-14 text-lg' index={5} />
                </InputOTPGroup>
              </InputOTP>
            </div>
            <div className='mt-4 grid w-full gap-2'>
              <Button
                disabled={value.length !== 6 || isSubmitting}
                type='submit'
              >
                Logga in
              </Button>
              <Button type='button' onClick={clearAndClose} variant='outline'>
                Avbryt
              </Button>
            </div>
          </form>
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
