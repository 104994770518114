import type { ResponseCredentials } from '@/app/(auth)/types';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import type { UseMutationOptions } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';

type Props = {
  username: string;
  password: string;
  customerMode: boolean;
  mfaType: 0 | 1;
};

export function useAuthHotpLink(
  options?: Omit<
    UseMutationOptions<ResponseCredentials, unknown, Props>,
    'mutationFn'
  >
) {
  return useMutation({
    ...options,
    mutationFn: (props) => distributeHotpLink(props),
  });
}

async function distributeHotpLink(props: Props) {
  const { cookies } = await getCookies();
  const res = await fetch(
    `${BASE_BACKEND_URL}/accounts/users/auth/login/credentials/`,
    {
      method: 'POST',
      headers: {
        'X-PIGELLO-CUSTOMER-ID': cookies.customer_id,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username: props.username,
        password: props.password,
        user_type: props.customerMode
          ? 'accounts.tenant'
          : 'accounts.organizationuser',
        distribute_hotp_to: props.mfaType,
      }),
    }
  );

  // if (!res.ok) throw new Error(await res.text());

  return res.json();
}
