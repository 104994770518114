import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
} from '@/components/ui/input-otp';
import { Label } from '@/components/ui/label';
import OverlayLoader from '@/components/ui/overlay-loader';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { getCookies } from '@/requests/cookies';
import { DevicePhoneMobileIcon, EnvelopeIcon } from '@heroicons/react/16/solid';

import {
  useState,
  type Dispatch,
  type KeyboardEvent,
  type SetStateAction,
} from 'react';
import QRCode from 'react-qr-code';
import { toast } from 'sonner';

interface ActivateMFAProps {
  isSubmitting: boolean;
  isActivateMFA: boolean;
  setIsActivateMFA: Dispatch<SetStateAction<boolean>>;
  username: string;
  password: string;
  customerMode: boolean;
}

export function ActivateMFA({
  isSubmitting,
  isActivateMFA,
  setIsActivateMFA,
  username,
  password,
  customerMode,
}: ActivateMFAProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [kind, setKind] = useState<'totp' | 'hotp' | null>(null);

  const [totpUrl, setTotpUrl] = useState<string | null>(null);
  const [mfaCode, setMfaCode] = useState<string | null>(null);

  const handleGetTotpUrl = async () => {
    setIsLoading(true);

    try {
      const { cookies } = await getCookies();

      const res = await fetch(
        `${BASE_BACKEND_URL}/accounts/users/auth/credentials/totp/enforced/`,
        {
          method: 'POST',
          headers: {
            'X-PIGELLO-CUSTOMER-ID': cookies.customer_id,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,

            user_type: customerMode
              ? 'accounts.tenant'
              : 'accounts.organizationuser',
          }),
        }
      );

      const data = await res.json();

      setIsLoading(false);

      setTotpUrl(data.url);
    } catch (e) {
      setIsLoading(false);

      toast.error('Kunde inte hämta QR-kod', {
        description: JSON.stringify(e),
      });
    }
  };

  const activateTotp = async () => {
    setIsLoading(true);

    try {
      const { cookies } = await getCookies();

      const res = await fetch(
        `${BASE_BACKEND_URL}/accounts/users/auth/credentials/totp/enforced/`,
        {
          method: 'POST',
          headers: {
            'X-PIGELLO-CUSTOMER-ID': cookies.customer_id,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
            mfa_code: mfaCode,
            user_type: 'accounts.organizationuser',
          }),
        }
      );

      if (!res.ok) throw new Error(await res.text());
      setIsLoading(false);

      toast.success('2-faktorsautentisering aktiverad', {
        description: 'Du kan nu logga in med 2-faktorsautentisering',
      });

      setIsActivateMFA(false);
    } catch (e) {
      setIsLoading(false);

      toast.error('Kunde inte aktivera 2-faktorsautentisering', {
        description: JSON.stringify(e),
      });
    }
  };

  const handleKeyDown = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') {
      activateTotp();
    }
  };

  const activateHotp = async () => {
    setIsLoading(true);

    try {
      const { cookies } = await getCookies();

      const res = await fetch(
        `${BASE_BACKEND_URL}/accounts/users/auth/credentials/hotp/enforced/`,
        {
          method: 'POST',
          headers: {
            'X-PIGELLO-CUSTOMER-ID': cookies.customer_id,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
            distribute_email: true,
            user_type: customerMode
              ? 'accounts.tenant'
              : 'accounts.organizationuser',
          }),
        }
      );

      if (!res.ok) throw new Error(await res.text());

      toast.success('2-faktorsautentisering aktiverad', {
        description: 'Du kan nu logga in med 2-faktorsautentisering',
      });

      setIsLoading(false);

      setIsActivateMFA(false);
    } catch (e) {
      setIsLoading(false);

      toast.error('Kunde inte aktivera 2-faktorsautentisering', {
        description: JSON.stringify(e),
      });
    }
  };

  const clearAndClose = () => {
    setIsActivateMFA(false);
  };

  return (
    <AlertDialog open={isActivateMFA} onOpenChange={clearAndClose}>
      <AlertDialogContent className='relative w-[calc(100%-2rem)] max-w-md'>
        {(isSubmitting || isLoading) && <OverlayLoader />}
        <AlertDialogHeader>
          <AlertDialogTitle>Aktivera 2-faktorsautentisering</AlertDialogTitle>
          {kind === null && (
            <AlertDialogDescription>
              Organisationen har valt att kräva 2-faktorsautentisering. Välj om
              du vill använda 2-faktorsautentisering via mail eller aktivera
              TOTP (Verifiering via ex. Google Authenticator eller Microsoft
              Authenticator).
            </AlertDialogDescription>
          )}
          {kind === 'hotp' && (
            <AlertDialogDescription>
              2-faktorsautentisering via mail kommer att aktiveras. Du kommer
              att få en mail med en inloggningskod varje gång du loggar in.
            </AlertDialogDescription>
          )}
          {kind === 'totp' && (
            <AlertDialogDescription>
              2-faktorsautentisering via app kommer att aktiveras.
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>

        {kind === 'hotp' && (
          <Button className='self-end' onClick={activateHotp}>
            Jag förstår, aktivera verifiering via mail
          </Button>
        )}

        {kind === 'totp' && (
          <>
            {!totpUrl && (
              <Card className='px-4 pb-4'>
                <h4 className='mb-2'>Förberedelser</h4>
                <ul>
                  <li className='mb-2 text-sm'>
                    1. Ladda ned antingen <strong>Google Authenticator</strong>{' '}
                    eller <strong>Microsoft Authenticator</strong> till din
                    mobiltelefon.
                  </li>

                  <li className='mb-2 text-sm'>
                    2. <strong>OBS: Viktigt.</strong> Vid aktivering kommer du
                    att få en <strong>återställningskod</strong> för att
                    återställa din inloggning utifall du ej längre kommer åt din
                    mobiltelefon för att generera koder. Denna måste{' '}
                    <strong>skrivas ner och förvaras säkert</strong>.
                  </li>
                  <li className='text-sm'>
                    3. I nästa steg kommer du att behöva scanna en QR-kod för
                    att koppla din app för 2-faktorsautentisering mot Pigello.
                    Var redo med mobilen och appen.
                  </li>
                </ul>

                <Button onClick={handleGetTotpUrl}>Fortsätt</Button>
              </Card>
            )}

            {totpUrl && (
              <Card className='grid gap-4 px-4 pb-4'>
                <h4>Scanna QR-koden nedan</h4>
                <div className='flex w-full items-center justify-center rounded-lg border py-4'>
                  <QRCode value={totpUrl} size={140} />
                </div>

                <Label className='self-start' htmlFor='mfaCode'>
                  Skriv sedan in din verifieringskod
                </Label>
                <InputOTP
                  id='mfaCode'
                  onChange={setMfaCode}
                  onKeyDown={handleKeyDown}
                  maxLength={6}
                >
                  <InputOTPGroup>
                    <InputOTPSlot className='size-14' index={0} />
                    <InputOTPSlot className='size-14' index={1} />
                    <InputOTPSlot className='size-14' index={2} />
                    <InputOTPSlot className='size-14' index={3} />
                    <InputOTPSlot className='size-14' index={4} />
                    <InputOTPSlot className='size-14' index={5} />
                  </InputOTPGroup>
                </InputOTP>

                <Button disabled={mfaCode?.length != 6} onClick={activateTotp}>
                  Aktivera TOTP
                </Button>
              </Card>
            )}
          </>
        )}

        {kind === null && (
          <div className='grid grid-cols-1 gap-4'>
            <Button
              variant='secondary'
              className='self-end'
              onClick={() => setKind('hotp')}
            >
              Aktivera verifiering via mail
              <EnvelopeIcon className='ml-1 size-4' />
            </Button>
            <Button
              variant='secondary'
              className='self-end'
              onClick={() => setKind('totp')}
            >
              Aktivera TOTP <DevicePhoneMobileIcon className='ml-1 size-4' />
            </Button>
          </div>
        )}
      </AlertDialogContent>
    </AlertDialog>
  );
}
